
import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent,
  IonButtons,
  IonButton,
  IonBackButton,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonSelectOption,

  onIonViewDidEnter
 } from '@ionic/vue';

import { checkmark } from 'ionicons/icons';

import { defineComponent, ref } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { LoginResult, ApiError, isApiError } from '@/lib/api-endpoints';
import MInput from '@/components/MInput.vue';
import MSelect from '@/components/MSelect.vue';
import MPassword from '@/components/MPassword.vue';
import { useStore } from '@/store';
import { getEnum, ServerEnum } from '@/lib/server-enums';
import * as yup from 'yup';
import t from '@/translation';
import { showModal } from '@/lib/modal';
import { useForm } from 'vee-validate';


export default  defineComponent({
  name: 'WeddingInfo',
  components: { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent,
    IonButtons,
    IonButton,
    IonBackButton,
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    //IonDatetime,
    IonSelectOption,


    MInput,
    MSelect,
    MPassword,
  },
  setup(){

    const store = useStore();
    const router = useRouter();

    const registerSchema = yup.object({
      name: yup.string().required().label(t("Name")),
      email: yup.string().email().required().label(t("Email")),
      emailConfirmation: yup.string().required(t("Please confirm the email address"))
       .oneOf([yup.ref('email')], t('Email confirmation must match')),
      // eslint-disable-next-line @typescript-eslint/camelcase
      country_code: yup.string().required().label(t("Country")),
      password: yup.string().min(6).label(t('Current Password')).required(),
    });

    // Create a form context with the validation schema
    const { values, setFieldError, validate } = useForm({
      validationSchema: registerSchema,
    });


    const countries = ref([] as ServerEnum[]);

    // Load up the latest user details when entering this page
    onIonViewDidEnter(async () =>{
      // load the list of countries.
      countries.value = await getEnum('countries'); 
      
      const userData = await store.dispatch("getUserData", {force:true});
      if ("errors" in userData){
        await showModal(t("Error Fetching Info"), t("This application was unable to retreive your user information.  Please make sure you have internet access, and then login again."));
        router.push("/login");
      } else {
        // load the user details into the form
        Object.assign(values, userData);
        // eslint-disable-next-line @typescript-eslint/camelcase
        values.country_code = userData.country && userData.country.code;
        values.emailConfirmation = userData.email;
        values.password = undefined; // make sure the password is empty.
      }
    });

    async function onSubmitForm(){
      const result = await validate();
      if (result.valid){
        const userData: ApiError | LoginResult = await store.dispatch('updateUser', values);
        if (! isApiError(userData)){
          values.password = undefined; // clear the password only.
          await showModal(t("Success"), t("Your details were successfully updated."));
          setFieldError('password', undefined);
          router.go(-1); // go back to the last page.
        }
      } 
    }

    return {
      onSubmitForm,
      countries,
      checkmark,
    }
  }
});
